import Analytics from '@hh.ru/analytics-js';
import vacancyResponseAttemptExternal from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/vacancy_response_attempt';

import { TopLevelSite } from 'src/models/topLevelSite';
import { UserType } from 'src/models/userType';
import fetcher from 'src/utils/fetcher';

/**
 * Попытка отклика
 *
 * @param {object} params
 * @param {string | number} params.employerId
 * @param {string | number} params.vacancyId
 */

interface SendResponseAttemptProps {
    employerId?: number;
    vacancyId: number;
    hhtmSource?: string;
    hhtmSourceLabel?: string;
    sendExternal?: boolean;
    topLevelSite?: TopLevelSite;
    userType?: UserType;
}

// tempexp_PORTFOLIO-30733_start

const RESPONSE_ATTEMPT_URL = '/shards/response/response_attempt';
declare global {
    interface FetcherPostApi {
        [RESPONSE_ATTEMPT_URL]: {
            queryParams: void;
            body: SendResponseAttemptProps;
            response: {
                shouldSendClientEvent: boolean;
            };
        };
    }
}

// tempexp_PORTFOLIO-30733_end

export const sendResponseAttempt = ({
    employerId,
    vacancyId,
    hhtmSourceLabel,
    sendExternal = false,
    topLevelSite,
    userType,
}: SendResponseAttemptProps): void => {
    // tempexp_PORTFOLIO-30733_start
    fetcher
        .post(RESPONSE_ATTEMPT_URL, {
            vacancyId,
            employerId: employerId || undefined,
            hhtmSourceLabel,
        })
        .then(({ data }) => {
            if (data.shouldSendClientEvent) {
                Analytics.sendHHEvent('responseAttempt', {
                    vacancyId: Number(vacancyId),
                    employerId: Number(employerId) || null,
                    hhtmSourceLabel,
                });
            }
        })
        .finally(() => void 0);
    // tempexp_PORTFOLIO-30733_end
    if (sendExternal && topLevelSite && userType) {
        const category = userType === UserType.Applicant ? 'applicant' : 'anonymous';
        // eslint-disable-next-line camelcase
        vacancyResponseAttemptExternal({ category, params: { product_id: vacancyId } });
        if (topLevelSite === TopLevelSite.RU) {
            Analytics.addEventPixels('B2C_VACANCY_RESPONSE_CLICK');
        }
    }
};
