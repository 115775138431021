import { type ReactNode, useRef } from 'react';

import {
    Link as MagritteLink,
    Text as MagritteText,
    VSpacingContainer,
    VSpacing,
    Cell,
    HSpacing,
    useCollapsible,
    useBreakpoint,
} from '@hh.ru/magritte-ui';
import { EyeOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { Link } from '@hh.ru/redux-spa-middleware';
import { type TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { type ResponseStatusResume } from 'src/models/applicantVacancyResponseStatuses';
import { BLACKLIST, NO_ONE, WHITELIST } from 'src/models/resume/resumeAccess.types';
import { type ResumeVisibility } from 'src/models/vacancyResponsePopup';
import { type VacancySearchItem } from 'src/models/vacancySearch/vacancySearchItem.types';
import { arrayToHumanReadableList } from 'src/utils/humanReadableList';

const getEmployersList = (employers: { employerId: number; name: string }[], andTrl: string): string => {
    const employersNames = employers.map((employer) => `«${employer.name}»`);
    return arrayToHumanReadableList(employersNames, andTrl);
};

const TrlKeys = {
    noOne: {
        toWhitelist: 'vacancy.response.popup.visibility.noOne',
        employerToWhitelist: 'vacancy.response.popup.visibility.noOne.companyInWhiteList',
        whitelistIsFull: 'vacancy.response.popup.visibility.noOne.responseDisabled',
    },
    whitelist: {
        addEmployer: 'vacancy.response.popup.visibility.addToWhiteList',
        whitelistIsFull: 'vacancy.response.popup.visibility.limitExceeded',
    },
    blacklist: {
        removeEmployerMany: 'vacancy.response.popup.visibility.removeFromBlackList.many',
        removeEmployerCurrent: 'vacancy.response.popup.visibility.removeFromBlackList',
    },
    changeVisibility: 'vacancy.response.popup.visibility.changeVisibility',
    incomplete: 'vacancy.response.popup.unfinished.hint',
    and: 'abstract.and',
};

interface ResumeHintProps {
    resume: ResponseStatusResume;
    visibility: ResumeVisibility;
    vacancy: VacancySearchItem;
    hidden: boolean;
    incomplete?: boolean;
    visible: boolean;
    single: boolean;
}

const ResumeHint: TranslatedComponent<ResumeHintProps> = ({
    trls,
    resume,
    visibility,
    vacancy,
    hidden,
    incomplete,
    visible,
    single,
}) => {
    const refNode = useRef<HTMLDivElement>(null);
    const { collapsibleClasses } = useCollapsible(refNode, visible, [visible]);
    const { isMobile } = useBreakpoint();

    if (!visibility || hidden) {
        return null;
    }

    const hash = resume.hash;

    const messages: ReactNode[] = [];

    if (incomplete) {
        messages.push(
            <MagritteText key="incomplete" Element="p" style="secondary" typography="label-3-regular">
                {trls[TrlKeys.incomplete]}
            </MagritteText>
        );
    }

    if ([NO_ONE, WHITELIST, BLACKLIST].includes(visibility.accessType)) {
        let message;

        if (visibility.accessType === NO_ONE) {
            if (visibility.whitelist.containsEmployer) {
                // «Не видно никому» меняется на «Только перечисленным компаниям»,
                // компания уже есть в белом списке (лимит в этом случае не важен)
                message = format(trls[TrlKeys.noOne.employerToWhitelist], {
                    '{0}': vacancy.company.visibleName,
                });
            } else if (!visibility.whitelist.containsEmployer && visibility.whitelist.canAdd <= 0) {
                // «Не видно никому» должно поменяться на «Только перечисленным компаниям»
                // с добавлением компании в белый список, но список заполнен
                message = trls[TrlKeys.noOne.whitelistIsFull];
            } else {
                // «Не видно никому» меняется на «Только перечисленным компаниям», компания добавляется в белый список
                message = format(trls[TrlKeys.noOne.toWhitelist], {
                    '{0}': vacancy.company.visibleName,
                });
            }
        }
        if (visibility.accessType === WHITELIST && !visibility.whitelist.containsEmployer) {
            if (visibility.whitelist.canAdd <= 0) {
                // Резюме видно только перечисленным компаниям,
                // компания должна добавиться в белый список, но список заполнен
                // (canAdd может быть отрицательным, если лимит превышен)
                message = format(trls[TrlKeys.whitelist.whitelistIsFull], {
                    '{0}': visibility.whitelist.limit,
                });
            } else {
                // Резюме видно только перечисленным компаниям,
                // компании нет в белом списке, при отклике она будет добавлена
                message = format(trls[TrlKeys.whitelist.addEmployer], {
                    '{0}': vacancy.company.visibleName,
                });
            }
        }
        if (visibility.accessType === BLACKLIST && visibility.connectedEmployers.length) {
            if (visibility.connectedEmployers.length === 1) {
                // Резюме видно всем компаниям, кроме перечисленных,
                // только текущая компания в черном списке пользователя и будет удалена из него
                message = format(trls[TrlKeys.blacklist.removeEmployerCurrent], {
                    '{0}': vacancy.company.visibleName,
                });
            } else {
                // Резюме видно всем компаниям, кроме перечисленных,
                // одна или несколько компаний, в которых работает менеджер этой вакансии
                // есть в чёрном списке и будет удалена из него
                message = format(trls[TrlKeys.blacklist.removeEmployerMany], {
                    '{0}': vacancy.name,
                    '{1}': getEmployersList(visibility.connectedEmployers, trls[TrlKeys.and]),
                });
            }
        }

        if (message) {
            messages.push(
                <VSpacingContainer key="visibility" default={8}>
                    <MagritteText Element="p" style="secondary" typography="label-3-regular">
                        {message}
                    </MagritteText>
                    <MagritteLink
                        Element={Link}
                        to={`/applicant/resumes/edit/visibility?resume=${hash}`}
                        target="_blank"
                        iconLeft={EyeOutlinedSize16}
                        typography="label-3-regular"
                    >
                        {trls[TrlKeys.changeVisibility]}
                    </MagritteLink>
                </VSpacingContainer>
            );
        }
    }

    if (!messages.length) {
        return null;
    }

    const content = (
        <Cell left={<HSpacing default={24} />}>
            <VSpacingContainer default={16}>{messages}</VSpacingContainer>
            <VSpacing default={single ? 0 : 16} />
        </Cell>
    );

    if (isMobile) {
        // Disabled collapse animation for small screens as it causes strange jumping effects in BottomSheet.
        return visible ? content : null;
    }

    return (
        <div ref={refNode} className={collapsibleClasses}>
            {content}
        </div>
    );
};

export default translation(ResumeHint);
